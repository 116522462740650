<template>
  <div
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <div class="top">语音技能</div>
    <div class="table-content">
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{ background: '#85c1f5', color: '#fff' }"
      >
        <el-table-column prop="title" label="技能" width="300">
        </el-table-column>
        <el-table-column prop="desc" label="描述" width="500">
        </el-table-column>
        <el-table-column prop="status" label="开关">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              @change="handleEdit(scope.$index, scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
export default {
  data() {
    return {
      tableData: [],
      opStatus: null,
      clientHeight: document.body.clientHeight,
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      apiShout.getskill().then((res) => {
        console.log("----;", res.data);
        if (res.data.code == 200) {
          this.tableData = res.data.result;
          this.tableData.forEach((aa) => {
            if (aa.status == 0) {
              aa.status = false;
            } else {
              aa.status = true;
            }
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleEdit(index, row) {
      console.log("ppp:", index, row);
      let loading = this.$loading({
        lock: true,
        text: "",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      if (row.status == true) {
        this.opStatus = 1;
      } else {
        this.opStatus = 0;
      }
      apiShout
        .getstaSkill({
          id: row.id,
          status: this.opStatus,
        })
        .then((res) => {
          console.log("开关：", res.data);
          if (res.data.code == 200) {
            loading.close();
            if (row.status == true) {
              this.$message({
                message: "打开成功！",
                type: "success",
              });
            } else {
              this.$message({
                message: "关闭成功！",
                type: "success",
              });
            }
          } else {
            loading.close();
            this.$message.error(res.data.msg);
          }
        });
    },
  },
};
</script>

<style lang='less' scoped>
@color1: rgb(9, 170, 173);
@color2: #7f7f7f;
.table-content {
  padding: 15px 0 0 0;
  margin-right: 40px;
  margin-left: 28px;
}
/deep/ .el-table .cell {
  text-align: center;
}
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
.top {
  color: #7f7f7f;
  font-weight: bold;
  font-size: 17px;
  margin: 15px 0px 10px 28px;
}
/deep/ .el-table tr:nth-child(odd) {
  background-color: #fff;
}
/deep/ .el-table tr:nth-child(even) {
  background-color: rgba(252, 174, 115, 0.07058823529411765);
}
/deep/.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #e6f7ff;
}
</style>